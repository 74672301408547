<template>
  <div>
    <router-link
      :to="{
        name: 'product-details',
        params: { seo_url: product?.seo_url },
      }"
      @click="showProductDetails(product?.seo_url)"
    >
      <div
        class="product"
        @mouseenter="hidePrice()"
        @mouseleave="showPrice()"
        v-if="product"
      >
        <div class="product__info-container">
          <div
            class="product__badge"
            v-if="product && product.badge && product.badge.label"
          >
            <div :class="{ 'product__badge-red': product.badge.label != 'NEU' }">
              <span>{{ product.badge.label }}</span>
            </div>
          </div>
          <div class="product__image" v-if="product && product.badge">
            <app-image
              v-if="product.images[0].identifier"
              :src="`${product.images[0]?.identifier}`"
              alt="Productbild"
            ></app-image>
          </div>
          <div class="product__brand" v-if="product.brand">
            <app-image
              :alt="`${product.brand.name}`"
              :src="`${product.brand.image}`"
            ></app-image>
          </div>
        </div>

        <div class="switch-button" :class="{ 'no-button': priceShow }">
          <div class="switch-button__slider">
            <div class="switch-button__button">
              <div class="product__info" :class="{ 'no-button': priceShow }">
                <div
                  class="product__info-btn-warenkorb"
                  v-if="product.stock_status_id != 'NO_STOCK'"
                >
                  <AddToCartButton
                    data-rewardsshop-action="cart-add"
                    :data-rewardsshop-cart-arrayposition="cartArrayIndex"
                    :data-rewardsshop-cart-add-quantity="1"
                    :data-rewardsshop-cart-add-location="route.meta.trackingName"
                    :product-sku="product.sku"
                  ></AddToCartButton>
                </div>

                <div class="product__info-btn-warenkorb" v-else>
                  <div class="py-4 not-available">Derzeit nicht verfügbar</div>
                </div>
              </div>
            </div>

            <div>
              <div class="product__info">
                <div class="product__info-name">
                  {{ product.name }}
                </div>
                <div class="col-span-5 text-left py-1">
                  <div class="flex row justify-start gap-x-4">
                    <div
                      v-if="$route.path.indexOf('warenkorb') >= 0"
                      class="flex row justify-start gap-x-4 w-full"
                    >
                      <div
                        class="product__info-price-default"
                        v-if="product && product.price && product.price.primary.COORDER"
                      >
                        {{ product.price.primary.COORDER.value }} °P
                      </div>
                      <div
                        class="product__info-price-mrsp"
                        v-if="
                          product &&
                          product.price &&
                          product.price.primary.DEFAULT &&
                          product.price.primary.COORDER
                        "
                      >
                        <span
                          :class="{
                            'product__info-price-mrsp-strike-through':
                              product.price.primary.DEFAULT,
                          }"
                          >{{ product.price.primary.DEFAULT.value }} °P</span
                        >
                      </div>
                    </div>
                    <div
                      v-else
                      class="flex row justify-start gap-x-2 sm:gap-x-4 flex-wrap w-full"
                    >
                      <div
                        class="product__info-price-default"
                        v-if="product && product.price && product.price.primary.DEFAULT"
                      >
                        {{ product.price.primary.DEFAULT.value }} °P
                      </div>
                      <div
                        class="product__info-price-mrsp"
                        v-if="product && product.price && product.price.primary.MRSP"
                      >
                        <span
                          :class="{
                            'product__info-price-mrsp-strike-through':
                              product.price.primary.MRSP,
                          }"
                          >{{ product.price.primary.MRSP.value }} °P</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="switch-button__button-cart-add-mobile">
                <div class="" :class="{ 'no-button': priceShow }">
                  <div
                    class="product__info-btn-warenkorb"
                    v-if="product.stock_status_id != 'NO_STOCK'"
                  >
                    <AddToCartButton
                      data-rewardsshop-action="cart-add"
                      :data-rewardsshop-cart-arrayposition="cartArrayIndex"
                      :data-rewardsshop-cart-add-quantity="1"
                      :data-rewardsshop-cart-add-location="route.meta.trackingName"
                      :cart-mobile="true"
                      :product-sku="product.sku"
                    ></AddToCartButton>
                  </div>

                  <div class="product__info-btn-warenkorb not-available" v-else>
                    <div class="py-4">Derzeit nicht verfügbar</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import AppImage from "@/components/Image/Image";
import AddToCartButton from "@/components/Button/AddToCartButton";
import { ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  props: ["product", "index"],
  name: "Short",
  components: { AddToCartButton, AppImage },
  setup() {
    const priceShow = ref(true);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const hidePrice = () => {
      if (window.matchMedia("(min-width: 767px)").matches) {
        priceShow.value = false;
      }
    };
    const showPrice = () => {
      priceShow.value = true;
    };
    const showProductDetails = async (seo_url) => {
      await store.dispatch("sliderImages/setImages", { data: [], type: "" });
      router.push({
        name: "product-details",
        params: { seo_url: seo_url },
      });
    };
    const cartArrayIndex = computed(() => {
      return store.getters["cart/getCartArrayIndex"];
    });

    return {
      hidePrice,
      showPrice,
      priceShow,
      showProductDetails,
      route,
      cartArrayIndex,
    };
  },
};
</script>
<style lang="postcss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.product {
  min-height: 290px;

  @media screen and (max-width: 400px) {
    min-height: 10rem;
  }

  @apply p-2;

  &__info-container {
    min-height: 180px;
    @apply relative;
  }

  &__badge {
    @apply bg-badge-neu z-100 absolute text-center top-0 right-0 rounded-lg;
    font-size: 11px;
    line-height: 14px;

    & > div {
      @apply rounded-lg px-2 py-1;
    }

    &-red {
      @apply bg-red-light;
    }

    span {
      @apply text-white rounded-lg;
    }
  }

  &__image {
    img {
      @apply w-full h-full m-auto object-contain z-10;
    }
  }

  &__brand {
    @apply flex flex-col justify-start items-start absolute;
    bottom: 0px;

    img {
      @apply object-contain relative w-auto;
      height: 28px;
      top: 15px;
    }
  }

  &__info {
    @apply grid grid-cols-5 border-t border-solid mt-5 mb-0;

    border-color: rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: auto;

    &-name {
      @apply col-span-5 text-gray-dark text-xs pt-3 pb-4;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @media screen and (max-width: 600px) {
        overflow: hidden;
        text-overflow: ellipsis;
        /* max-height is important for the clamp to work in a grid!!! 
                              max-height: 54px;*/

        white-space: normal;

        /* autoprefixer: off */
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        flex: 1;
      }
    }

    &-price-default {
      @apply text-blue-light font-bold;
    }

    &-price-mrsp {
      @apply text-gray-dark;

      &-strike-through {
        @apply relative left-0 right-0 top-0 bottom-0;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHZpZXdCb3g9IjAgMCAxMDAlIDEwMCUiPjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlPSJyZ2JhKDE5MywgMCwgNDMsIDEpIiB4MT0iMyUiIHkxPSI5MCUiIHgyPSI5NyUiIHkyPSIxMCUiLz48L3N2Zz4=)
          center center no-repeat;
        background-size: 100% 100%;
      }
    }

    &-btn-warenkorb {
      @apply col-span-5 justify-center items-center text-center text-blue-light mt-2 mb-2;
    }
  }
}

.switch-button {
  position: relative;
  height: 109px;
  overflow: hidden;
  top: 0px;
  width: 100%;

  &__button * {
    opacity: 1;
    transition: opacity 0.54s;

    &.no-button {
      opacity: 0;
    }
  }

  &__button-cart-add-mobile {
    display: none;
  }

  &__slider {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.54s;
    transform: translateY(0px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 767px) {
      transform: translateY(-90px);
    }
  }

  &.no-button {
    & > div {
      transform: translateY(-90px);
      height: 179px;

      @media screen and (max-width: 400px) {
        transform: translateY(-95px);
        height: 200px;
        min-height: 225px;
      }
    }
  }

  .not-available {
    height: 57px;

    @media screen and (max-width: 600px) {
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.coorder-products {
  .product {
    @media screen and (max-width: 400px) {
      min-height: 290px;
    }

    &__info {
      @media screen and (max-width: 400px) {
        min-height: 90px;
      }

      &-name {
        @media screen and (max-width: 767px) {
          height: 54px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        @media screen and (max-width: 400px) {
          max-height: 54px;
        }
      }
    }
  }

  .switch-button {
    @media screen and (max-width: 767px) {
      height: 176px;

      & > div {
        transform: translateY(-50px);
        height: 179px;
      }
    }

    &__button-cart-add-mobile {
      display: none;

      @media screen and (max-width: 767px) {
        display: block;

        button.white {
          width: 145px;
        }
      }
    }

    &.no-button {
      transform: translateY(0);

      @media screen and (max-width: 767px) {
        & > div {
          transform: translateY(-50px);
          height: 179px;
        }
      }

      @media screen and (max-width: 400px) {
        & > div {
          transform: translateY(-50px);
          height: 130px;
          min-height: 160px;
        }
      }
    }
  }
}
</style>
